exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boring-js": () => import("./../../../src/pages/boring.js" /* webpackChunkName: "component---src-pages-boring-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-js": () => import("./../../../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-writings-js": () => import("./../../../src/pages/writings.js" /* webpackChunkName: "component---src-pages-writings-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

